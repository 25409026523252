<template>
  <div class="helper">
    <div class="helper-sections">
      <div
        v-for="(section, sectionKey) in sections"
        :key="sectionKey"
        class="helper-section"
      >
        <div class="helper-section-title">
          <strong>{{ section.title }}</strong>
        </div>
        <ul class="helper-section-list">
          <li v-for="(operator, key) in section.operators" :key="key" @click="stringToClipboard(operator.template)">
            <i class="fal fa-clipboard-list"></i>{{ operator.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sections: {
      type: Array,
      default: null
    }
  },
  methods: {
    stringToClipboard(template) {
      this.$copyText(template);
    }
  }
};
</script>
